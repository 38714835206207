<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            Calendario
            <template v-slot:actions>
                <v-icon color="primary" @click.native.stop="reloadView++">
                    mdi-refresh
                </v-icon>
                <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })">
                    mdi-close
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reloadView">
            <template>
                <!--v-row class="white"
                    style="width: 98%; margin-left: 1%; margin-right: 1%; margin-top: 30px; margin-bottom: 10px;">
                    <v-col>
                        <v-btn class="mr-4 mb-4 mt-4 btn-add" @click="showForm = true">
                            Agregar cita
                        </v-btn>
                    </v-col>
                </v-row-->
                <v-row
                    style="background: white; width: 98%; margin: 0 auto; margin-left: 1%; margin-right: 1%; margin-top: 30px; margin-bottom: 30px;">
                    <v-col>
                        <v-sheet height="64">
                            <v-toolbar flat>
                                <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                                    Hoy
                                </v-btn>
                                <v-btn text small color="grey darken-2" @click="prev">
                                    <v-icon small>
                                        mdi-chevron-left
                                    </v-icon>
                                </v-btn>
                                <v-btn text small color="grey darken-2" @click="next">
                                    <v-icon small>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-toolbar-title v-if="$refs.calendar">
                                    {{ $refs.calendar.title }}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-menu bottom right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                            <span>{{ typeToLabel[type] }}</span>
                                            <v-icon right>
                                                mdi-menu-down
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click="type = 'day'">
                                            <v-list-item-title>Ver por día</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="type = 'week'">
                                            <v-list-item-title>Ver por semana</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="type = 'month'">
                                            <v-list-item-title>Ver por mes</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-spacer></v-spacer>
                                <v-btn text class="btn-add" @click="showForm = true">
                                    Agregar cita
                                </v-btn>
                            </v-toolbar>
                        </v-sheet>
                        <v-sheet height="600">
                            <v-calendar style="padding-bottom: 20px;" :locale="locale" ref="calendar" v-model="focus" color="primary" :events="events"
                                :event-color="getEventColor" :type="type" @click:event="showEvent" @click:more="viewDay"
                                @click:date="viewDay" @change="updateRange"></v-calendar>
                            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement"
                                offset-x>
                                <v-card color="grey lighten-4" min-width="350px" flat>
                                    <v-toolbar :color="selectedEvent.color" dark>
                                        <v-btn color="error" icon @click="delSelectedOpen"
                                            v-if="selectedEvent.name != 'Cita de vacunación' && selectedEvent.name != 'Cita de medicación' && selectedEvent.name != 'Cita médica' && selectedEvent.name != 'Cita de peluquería'">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <!--v-btn icon>
                                            <v-icon>mdi-heart</v-icon>
                                        </v-btn>
                                        <v-btn icon>
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn-->
                                    </v-toolbar>
                                    <v-card-text style="background: white;">
                                        <span v-html="selectedEvent.details"></span>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn text color="secondary" @click="selectedOpen = false">
                                            Cerrar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-dialog v-model="showForm" width="500px" style="min-width: 400px;">
                    <v-card>
                        <v-row>
                            <v-col >
                                <v-card-title class="mx-1 my-1">
                                    Agregar cita
                                </v-card-title>
                            </v-col>
                            <v-col cols="3" md="3" class="d-flex justify-end mx-1 my-1">
                                <v-btn class="btndelete" @click="showForm = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-text-field outlined dense v-model="appointmentForm.AppointmentName"
                                        label="Titulo de cita"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea outlined dense v-model="appointmentForm.AppointmentDetails"
                                        label="Detalle de la cita"></v-textarea>
                                </v-col>
                            </v-row>
                            <!-- <v-form ref="appointments">
                            </v-form> -->
                            <v-row>
                                <v-col>
                                    <date-picker outlined dense label="Fecha de inicio"
                                        @dateSelected="(param) => (appointmentForm.AppointmentStartDate = param)"
                                        :value="appointmentForm.AppointmentStartDate"></date-picker>
                                </v-col>

                                <v-col>
                                    <v-flex>
                                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                            :return-value.sync="appointmentForm.AppointmentStartHour" lazy transition="scale-transition" offset-y full-width
                                            max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field outlined dense v-model="appointmentForm.AppointmentStartHour" label="Hora de inicio"
                                                readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-time-picker outlined dense v-if="menu2" v-model="appointmentForm.AppointmentStartHour" full-width
                                                @click:minute="$refs.menu2.save(appointmentForm.AppointmentStartHour)"></v-time-picker>
                                        </v-menu>
                                    </v-flex>
                                    <!--v-text-field outlined dense label="Hora de inicio" v-model="appointmentForm.AppointmentStartHour" @input="updateDateTime" /-->
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <date-picker outlined dense label="Fecha fin"
                                        @dateSelected="(param) => (appointmentForm.AppointmentEndDate = param)"
                                        :value="appointmentForm.AppointmentEndDate"></date-picker>
                                </v-col>

                                <v-col>
                                    <v-flex>
                                        <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                                            :return-value.sync="appointmentForm.AppointmentEndHour" lazy transition="scale-transition" offset-y full-width
                                            max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field outlined dense v-model="appointmentForm.AppointmentEndHour" label="Hora de fin"
                                                readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-time-picker outlined dense v-if="menu3" v-model="appointmentForm.AppointmentEndHour" full-width
                                                @click:minute="$refs.menu3.save(appointmentForm.AppointmentEndHour)"></v-time-picker>
                                        </v-menu>
                                    </v-flex>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-autocomplete attach outlined dense v-model="appointmentForm.ID_Customer" :items="customers" item-text="NameCustomer" item-value="ID_Customer" label="Seleccione el cliente" v-on:change="getPatient" ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row v-if="businesstype === 'veterinary' || businesstype === 'medicalclinic'">
                                <v-col>
                                    <v-autocomplete attach outlined dense v-model="appointmentForm.ID_Patient" :items="patients" item-text="NamePatient" item-value="ID_Patient" label="Seleccione el paciente" ></v-autocomplete>
                                </v-col>
                            </v-row>


                        </v-card-text>
                        <v-card-actions class="d-flex flex-row-reverse ">
                            <v-btn class="btnsave" @click="addAppointmentDate">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
                </alerts>
            </template>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>

import DatePicker from '@/components/DatePicker.vue';
import Alerts from '@/components/Alerts';

export default {
    name: "MedicalCalendar",
    components: { DatePicker, Alerts },
    props: ["win"],
    data() {
        return {
            reloadView: 0,
            locale: 'es',
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Vista por mes',
                week: 'Vista por semana',
                day: 'Vista por día',
                '4day': '4 Days',
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            showForm: false,
            appointmentForm: {
                AppointmentStartHour: '07:00',
                AppointmentEndHour: '07:30'
            },
            menu2: false,
            menu3: false,
            modal2: false,
            modal3: false,
            customers: [],
            patients: [],
            alert: {
                type: null,
                show: false,
                header: "",
                body: "",
            },
            businesstype: JSON.parse(localStorage.getItem("user")).businesstype
        }
    },
    mounted() {
        this.getCustomer()
        this.$refs.calendar.checkChange()
    },
    methods: {
        acceptAlert() {
            this.alert.show = false
        },
        showAlert(type, header, body, options = null){
            type = (type == null) ? "danger" : type
            this.alert.type = type
            this.alert.header = header
            this.alert.body = body
            this.alert.show = true
            this.alert.options = (options != null) ? options : null
        },
        closeAlert() {
            this.alert.show = false
            if (this.alert.options == 'add') {
                this.updateRange()
                this.appointmentForm.AppointmentStartHour = '07:00'
                this.appointmentForm.AppointmentEndHour = '07:30'
                this.appointmentForm.AppointmentName = null
                this.appointmentForm.AppointmentDetails = null
                this.appointmentForm.AppointmentStartDate = null
                this.appointmentForm.AppointmentEndDate = null
                this.appointmentForm.ID_Customer = null
                this.appointmentForm.ID_Patient = null
            }
        },
        async getCustomer(){
            this.$API.medicalcalendar.getCustomer().then((response) => {
                this.customers = response
            }).catch((err) => {
                console.log(err)
            })
        },
        async getPatient(){
            this.$API.medicalcalendar.getPatient({ ID_Customer: this.appointmentForm.ID_Customer }).then((response) => {
                this.patients = response
                console.log('INFO PATIENT', response)
            }).catch((err) => {
                console.log(err)
            })
        },
        viewDay({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor(event) {
            return event.color
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        updateRange() {
            const events = []
            this.$API.medicalcalendar.getAppointmentsOfMedicalForms().then((response) => {

                // console.log('INFO CALENDAR', response)
                for (let i = 0; i < response.prescription.length; i++) {
                    events.push({
                        name: "Cita médica",
                        details: "<b>Cliente: </b> " + response.prescription[i].NameCustomer + "<br/><b>Paciente: </b>" + response.prescription[i].NamePatient + "<br/><b>Próxima cita: </b>" + response.prescription[i].NextAppointment,
                        start: this.setDate(response.prescription[i].NextAppointment),
                        end: this.setDate(response.prescription[i].NextAppointment),
                        color: 'green',
                        timed: false
                    })
                }

              /*   for (let i = 0; i < response.vaccineAndMedication.length; i++) {
                    let vaccOrMedicine1 = response.vaccineAndMedication[i].ItemType == 'VAC' ? "vacunación" : 'medicación'
                    let vaccOrMedicine2 = response.vaccineAndMedication[i].ItemType == 'VAC' ? "la vacuna" : 'el medicamento'
                    let text = "<b>Nota:</b> Se espera su visita para recibir " + vaccOrMedicine2 + " " + response.vaccineAndMedication[i].NameItem
                    events.push({
                        name: "Cita de " + vaccOrMedicine1,
                        details: "<b>Cliente: </b> " + response.vaccineAndMedication[i].NameCustomer + "<br/><b>Paciente: </b>" + response.vaccineAndMedication[i].NamePatient + "<br/>" + text,
                        start: this.setDate(response.vaccineAndMedication[i].NextAppointment),
                        end: this.setDate(response.vaccineAndMedication[i].NextAppointment),
                        color: 'blue',
                        timed: false
                    })
                } */

                /* for (let i = 0; i < response.grooming.length; i++) {
                    events.push({
                        name: "Cita de peluquería",
                        details: "<b>Cliente: </b> " + response.grooming[i].NameCustomer + "<br/><b>Paciente: </b>" + response.grooming[i].NamePatient + "<br/><b>Peluquero: </b>" + response.grooming[i].Groomer,
                        start: this.setDate(response.grooming[i].NextAppointment),
                        end: this.setDate(response.grooming[i].NextAppointment),
                        color: 'purple',
                        timed: false
                    })
                } */

                for (let i = 0; i < response.calendar.length; i++) {
                    events.push({
                        id: response.calendar[i].ID,
                        name: response.calendar[i].AppointmentName,
                        details: "<b>Cliente: </b> " + response.calendar[i].NameCustomer ? response.calendar[i].NameCustomer : "" + "<br/>" +
                            (this.businesstype === 'veterinary' || this.businesstype === 'medicalclinic' ? "<b>Paciente: </b> " + response.calendar[i].NamePatient + "<br/>" : "") +
                            "<b>Detalles de la cita: </b> " + response.calendar[i].AppointmentDetails,
                        start: this.setDateWithHour(response.calendar[i].AppointmentStartDate, response.calendar[i].AppointmentStartHour),
                        end: this.setDateWithHour(response.calendar[i].AppointmentEndDate, response.calendar[i].AppointmentEndHour),
                        color: 'grey darken-1',
                        timed: true
                    })
                }

                this.events = events
            }).catch((err) => {
                console.log(err)
                this.events = events
            })
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },

        setDate(date) {
            try {
                const dateStr = date;
    
                const splitDate = dateStr.split('/');
                const day = parseInt(splitDate[0], 10);
                const month = parseInt(splitDate[1], 10) - 1; // Resta 1 al mes porque en JavaScript los meses van de 0 a 11
                const year = parseInt(splitDate[2], 10);
    
                const dateFormat = new Date(year, month, day);
    
                return dateFormat
            } catch (error) {
                console.log(error);
                throw error;
            }
        },

        setDateWithHour(dateStr, hourStr) {
            try {
                // console.log( dateStr, hourStr )
                const [day, month, year] = dateStr.split('/').map(Number);
                const [hour, minute] = hourStr.split(':').map(Number);
    
                const date = new Date(year, month - 1, day, hour, minute);
                return date
                
            } catch (error) {
                console.log(error)
                throw error;
            }
            //const formatISO = date.toISOString();
            //const finalFormat = formatISO.substr(0, 11) + hourStr + ':00';
            //return finalFormat;
        },

        updateDateTime() {
            if (this.selectedDate && this.selectedTime) {
                const [hours, minutes] = this.selectedTime.split(':');
                this.selectedDate.setHours(Number(hours), Number(minutes), 0, 0);
            }
        },

        addAppointmentDate(){
            const { AppointmentEndDate, AppointmentStartDate } = this.appointmentForm;
            
            if( !AppointmentStartDate ) {
                // this.showForm = false
                this.showAlert("warning", "Advertencia", "Debe definir una fecha de inicio de la cita")
                return;
            }
            
            if( !AppointmentEndDate ) {
                // this.showForm = false
                this.showAlert("warning", "Advertencia", "Debe definir una fecha de finalizacion de la cita")
                return;
            }

            if(this.businesstype === 'veterinary'){
                if(!this.appointmentForm.ID_Patient || !this.appointmentForm.ID_Customer ){
                    // this.showForm = false
                    this.showAlert("warning", "Advertencia", "Debe llenar todos los campos de paciente y cliente")
                    return
                }
            } 
            // else {
            //     if(!this.appointmentForm.ID_Customer ){
            //         this.showForm = false
            //         this.showAlert("warning", "Advertencia", "Debe llenar todos los campos de paciente y cliente")
            //         return
            //     }
            // }

            // return;

            this.$API.medicalcalendar.postAddAppointment({ appointment: this.appointmentForm }).then(response => {
                this.showForm = false
                this.showAlert("success", "Exito", response.message, "add")
            })  
            .catch(err => {
                this.showAlert("danger", "Error", err.message)
            })
        },

        delSelectedOpen() {
            if (this.selectedEvent){
                const eventIdToDelete = this.selectedEvent.id;

                this.$API.medicalcalendar.postDelAppointment({ ID_Appointment: eventIdToDelete}).then(response => {
                    this.events = this.events.filter(event => event.id !== eventIdToDelete)
                    this.selectedOpen = false
                    this.showAlert("success", "Exito", response.message)
                })
                .catch(err => {
                    this.selectedOpen = false
                    this.showAlert("danger", "Error", err.message)
                })
                
            }
        }
    },
}
</script>
<style>
.v-btn--fab.v-size--small {
    top: 0px !important;
}

.btndelete {
    background-color: #E3191E !important;
    color: white;
}

.btn-add {
    background-color: #3498DB !important;
    color: white;
}

.btnclean {
    background-color: #F29D35 !important;
    color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

</style>